import React, {useEffect} from "react";
import styled from "styled-components";
import { useGate, useUnit } from "effector-react";
import { useNavigate, useParams } from "react-router-dom";
import { $session } from "entities/user";
import { $task, $taskPending } from "entities/task";
import { Title, Stack, Priority, DateTime, PropertyList, StorybookIcon, FigmaIcon } from "shared/ui";
import { useTelegram } from "app/providers";
import { formatDateDMYHM } from "shared/lib";
import { TaskStatus } from "shared/api";
import { TaskGate, $props, startTask, updateStatusTask } from "./model";

export const TaskPage: React.FC = () => {
  const {id} = useParams();
  useGate(TaskGate, id);

  const { tg } = useTelegram();
  const navigate = useNavigate();
  const [task, $loading, props, session] = useUnit([$task, $taskPending, $props, $session]);

  useEffect(() => {
    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      navigate('/tasks')
    });

    return () => {
      tg.BackButton.hide();
    }
  }, [tg, navigate])

  useEffect(() => {
    if(task && session) {
      if(!task.assignedTo) {
        tg.MainButton.text = "Взять в работу";
        tg.MainButton.show();
        tg.MainButton.onClick(() => {
          tg.MainButton.hide();
          startTask();
        });
      }

      if(task.assignedToId === session.id && task.status === TaskStatus.IN_PROGRESS) {
        tg.MainButton.text = "Отправить на проверку";
        tg.MainButton.show();
        tg.MainButton.onClick(() => {
          updateStatusTask(TaskStatus.REVIEW);
          navigate('/tasks/finish')
        });
      }
    }

    return () => {
      tg.MainButton.hide();
    }
  }, [tg, task, session]);

  if($loading) {
    return null
  }

  if(!task) {
    return (
      <TaskPageWrap>
        404
      </TaskPageWrap>
    )
  }

  return (
    <TaskPageWrap>
      <Stack gap="06">
        <Stack gap="04">
          <div className="header">
            <Priority level={task.priority}/>
            <DateTime date={formatDateDMYHM(task.createdDate)}/>
          </div>
          <Title>{task.title}</Title>
          <div className="description">{task.description}</div>
        </Stack>
        <div className="link-list">
          <div className="link">
            Дизайн-система «ЕФР»
            <FigmaIcon/>
          </div>
          <div className="link">
            Компонент отсутствует
            <StorybookIcon/>
          </div>
        </div>
        <PropertyList items={props}/>
      </Stack>
    </TaskPageWrap>
  );
};

const TaskPageWrap = styled.div`
    padding-top: var(--spacing--05);
    padding-bottom: var(--spacing--12);

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: var(--spacing--03);
    }

    .description {
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--tg-theme-subtitle-text-color);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.154px;
    }

    .link-list {
        display: flex;
        justify-content: space-between;
        gap: var(--spacing--05);

        .link {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: var(--spacing--03);
            padding: var(--spacing--03);
            border-radius: var(--radius-02, 6px);
            border: 1px solid var(--Wallet-separator_color, #C8C7CB);
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 138.462% */
            letter-spacing: -0.08px;
            max-width: 155px;
        }
    }
`