import React from "react";
import styled from "styled-components";
import { ITitle } from "./types";
import { styles } from "./constants";

export const Title: React.FC<ITitle> = ({className, children, size = "lg"}) => {
  return (
    <TitleWrap className={className} size={size}>
      {children}
    </TitleWrap>
  );
};

const TitleWrap = styled.div<ITitle>`
  color: var(--tg-theme-title-text-color);
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
`