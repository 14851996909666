import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { DateTime, Priority } from "shared/ui";
import { formatDateDMYHM } from "shared/lib";
import { createTaskLink } from "../lib";
import { ITask } from "shared/api";

export const TaskCard: React.FC<ITask> = ({id, title, description, priority, createdDate}) => {
  return (
    <TaskCardWrap to={createTaskLink(id)}>
      <div className="header">
        <Priority level={priority}/>
        <DateTime date={formatDateDMYHM(createdDate)}/>
      </div>
      <div className="content">
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </div>
    </TaskCardWrap>
  );
};

const TaskCardWrap = styled(Link)`
    display: flex;
    flex-direction: column;
    padding: var(--spacing--05);
    gap: var(--spacing--05);
    border-radius: var(--spacing--04);
    border: 1px solid var(--wallet-separator-color);
    text-decoration: none !important;
    align-items: flex-start;
    align-self: stretch;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: var(--spacing--03);
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: var(--spacing--02);
        
        .title {
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            color: var(--accent-black);
        }

        .description {
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--tg-theme-subtitle-text-color);
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
        }
    }
`