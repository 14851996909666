import React from "react";
import {IIcon} from "./types";

export const StorybookIcon: React.FC<IIcon> = ({width = 29, height = 37}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 29 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M28.9964 2.40588C28.9988 2.44347 29 2.48113 29 2.51879V34.8988C29 35.8984 28.1896 36.7087 27.19 36.7087C27.1629 36.7087 27.1358 36.7081 27.1088 36.7069L2.94911 35.6218C2.00818 35.5796 1.25693 34.8228 1.22161 33.8815L0.106399 4.16635C0.0695678 3.18497 0.822052 2.35326 1.80221 2.29201L21.6139 1.05304L21.4418 5.13371C21.4398 5.18075 21.4502 5.2272 21.4715 5.26858L21.4964 5.30811C21.5865 5.42691 21.7559 5.45016 21.8747 5.36004L23.4595 4.15789L24.798 5.21233C24.8482 5.25184 24.9107 5.2723 24.9745 5.27007C25.1236 5.26487 25.2401 5.13984 25.2349 4.99081L25.0852 0.83619L27.0771 0.712326C28.0747 0.649971 28.9341 1.4082 28.9964 2.40588Z" fill="#FF4785"/>
        <mask id="mask0_1157_2504" maskUnits="userSpaceOnUse" x="0" y="0" width="29" height="37">
          <path d="M1.22161 33.8815L0.106399 4.16635C0.0695678 3.18497 0.822052 2.35326 1.80221 2.29201L27.0771 0.712326C28.0747 0.649971 28.9341 1.4082 28.9964 2.40588C28.9988 2.44347 29 2.48113 29 2.51879V34.8988C29 35.8984 28.1896 36.7087 27.19 36.7087C27.1629 36.7087 27.1358 36.7081 27.1088 36.7069L2.94911 35.6218C2.00818 35.5796 1.25693 34.8228 1.22161 33.8815Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_1157_2504)">
          <path d="M21.4418 5.13367L21.6144 1.0522L25.0854 0.835449L25.2349 4.99077C25.2401 5.1398 25.1235 5.26483 24.9745 5.27003C24.9107 5.27226 24.8482 5.2518 24.798 5.21229L23.4595 4.15785L21.8747 5.36C21.7559 5.45012 21.5865 5.42687 21.4964 5.30806C21.4585 5.25805 21.4392 5.19639 21.4418 5.13367Z" fill="white"/>
        </g>
        <mask id="mask1_1157_2504" maskUnits="userSpaceOnUse" x="0" y="0" width="29" height="37">
          <path d="M1.22161 33.8815L0.106399 4.16635C0.0695678 3.18497 0.822052 2.35326 1.80221 2.29201L27.0771 0.712326C28.0747 0.649971 28.9341 1.4082 28.9964 2.40588C28.9988 2.44347 29 2.48113 29 2.51879V34.8988C29 35.8984 28.1896 36.7087 27.19 36.7087C27.1629 36.7087 27.1358 36.7081 27.1088 36.7069L2.94911 35.6218C2.00818 35.5796 1.25693 34.8228 1.22161 33.8815Z" fill="white"/>
        </mask>
        <g mask="url(#mask1_1157_2504)">
          <path d="M17.0028 14.2777C17.0028 14.9819 21.7461 14.6444 22.3828 14.1497C22.3828 9.35436 19.8098 6.83447 15.098 6.83447C10.3863 6.83447 7.74634 9.39357 7.74634 13.2322C7.74634 19.9179 16.7688 20.0458 16.7688 23.6925C16.7688 24.7162 16.2676 25.324 15.1648 25.324C13.7279 25.324 13.1598 24.5901 13.2267 22.095C13.2267 21.5537 7.74634 21.385 7.57926 22.095C7.1538 28.1416 10.9209 29.8856 15.2317 29.8856C19.4088 29.8856 22.6836 27.6591 22.6836 23.6285C22.6836 16.4631 13.5274 16.655 13.5274 13.1043C13.5274 11.6648 14.5968 11.4728 15.2317 11.4728C15.9 11.4728 17.103 11.5906 17.0028 14.2777Z" fill="white"/>
        </g>
      </g>
    </svg>
  );
};