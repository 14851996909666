import React from "react";
import styled from "styled-components";
import { types } from "./constants";
import { IPriority } from "./types";

export const Priority: React.FC<IPriority> = ({className, level}) => {
  return (
    <PriorityWrap className={className} level={level}>
      <img src={types[level].img} alt={types[level].text}/>
      <span>{types[level].text}</span>
    </PriorityWrap>
  );
};

const PriorityWrap = styled.div<IPriority>`
  display: flex;
  padding: var(--spacing--02) var(--spacing--03);
  justify-content: center;
  align-items: center;
  gap: var(--spacing--02);
  border-radius: var(--radius-04);
  background: var(--button-destructive-color);

  color: ${({level}) => types[level].textColor};
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  
  img {
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
  }
`