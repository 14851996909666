import okImg from "../../assets/ok.png";
import fireImg from "../../assets/fire.png";

interface ITypes {
  [key: number]: {
    text: string
    img: string
    textColor: string
  }
}

export const types: ITypes = {
  1: {
    text: "Срочно",
    img: fireImg,
    textColor: "var(--accent-red)"
  },
  2: {
    text: "Терпимо",
    img: okImg,
    textColor: "var(--accent-black)"
  }
}