import React from "react";
import styled from "styled-components";
import { useGate, useUnit } from "effector-react";
import { Menu } from "widgets/menu";
import { IBaseComponent } from "shared/types";
import { Title, Stack } from "shared/ui";
import { TaskList, $taskAll, $userTaskAll } from "entities/task";
import { TasksGate } from "./model";

export const TasksPage: React.FC<IBaseComponent> = ({className}) => {
  useGate(TasksGate);

  const [taskAll, userTaskAll] = useUnit([$taskAll, $userTaskAll])

  return (
    <TasksPageWrap className={className}>
      <Stack gap="04">
        <Title>Задачи</Title>
        <div>
          <p className="sub-title">↓ Мои</p>
          <TaskList tasks={userTaskAll}/>
        </div>
        <div>
          <p className="sub-title">↓ Остальные</p>
          <TaskList tasks={taskAll}/>
        </div>
        <Menu/>
      </Stack>
    </TasksPageWrap>
  );
};

const TasksPageWrap = styled.div`
    padding-top: var(--spacing--05);
    padding-bottom: var(--spacing--12);
      
    .sub-title {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        letter-spacing: -0.31px;
        color: var(--tg-theme-subtitle-text-color);
    }
`