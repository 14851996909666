const units = {
  px: (value: number) => `${value}px`,
  rem: (value: number) => `${value}rem`
}

const fontFamily = {
  sfpro: "SFPro"
};
const fontBaseSize = 1; // rem

const fontWeight = {
  thin: 100,
  light: 300,
  regular: 400,
  semiBold: 600,
  bold: 700,
  black: 700,
}

const fontSize = {
  size00: units.rem(fontBaseSize * 0.625), // 10px
  size01: units.rem(fontBaseSize * 0.75), // 12px
  size02: units.rem(fontBaseSize * 0.875), // 14px
  size03: units.rem(fontBaseSize), // 16px
  size04: units.rem(fontBaseSize * 1.125), // 18px
  size05: units.rem(fontBaseSize * 1.25), // 20px
  size06: units.rem(fontBaseSize * 1.375), // 22px
  size07: units.rem(fontBaseSize * 1.5), // 24px
  size08: units.rem(fontBaseSize * 1.75), // 28px
  size09: units.rem(fontBaseSize * 2), // 32px
  size10: units.rem(fontBaseSize * 2.125), // 36px
  size11: units.rem(fontBaseSize * 2.5), // 40px
  size12: units.rem(fontBaseSize * 3), // 48px
  size13: units.rem(fontBaseSize * 3.375), // 54px
  size14: units.rem(fontBaseSize * 3.75), // 60px
  size15: units.rem(fontBaseSize * 4.25), // 68px
  size16: units.rem(fontBaseSize * 4.75), // 76px
  size17: units.rem(fontBaseSize * 5.25), // 84px
  size18: units.rem(fontBaseSize * 5.75), // 92px
}

export const theme = {
  fontTokens: {
    body01: `
      font-family: ${fontFamily.sfpro};
      font-size: ${fontSize.size03};
      font-weight: ${fontWeight.regular};
      letter-spacing: ${units.px(0)};
      line-height: ${fontSize.size06};
    `,
    bodyLong01: `
      font-family: ${fontFamily.sfpro};
      font-size: ${fontSize.size03};
      font-weight: ${fontWeight.regular};
      letter-spacing: ${units.px(0)};
    `,
    bodyLong02: `
      font-family: ${fontFamily.sfpro};
      font-size: ${fontSize.size03};
      font-weight: ${fontWeight.semiBold};
      letter-spacing: ${units.px(0)};
    `,
    bodyShort01: `
      font-family: ${fontFamily.sfpro};
      font-size: ${fontSize.size01};
      font-weight: ${fontWeight.regular};
      letter-spacing: ${units.px(0)};
    `,
    label01: `
      font-family: ${fontFamily.sfpro};
      font-size: ${fontSize.size00};
      font-weight: ${fontWeight.regular};
      letter-spacing: ${units.px(0)};
    `,
    label03: `
      font-family: ${fontFamily.sfpro};
      font-size: ${fontSize.size01};
      font-weight: ${fontWeight.bold};
      letter-spacing: ${units.px(0)};
    `,
    helperText01: `
      font-family: ${fontFamily.sfpro};
      font-size: ${fontSize.size00};
      font-weight: ${fontWeight.regular};
      letter-spacing: ${units.px(0)};
    `,
    heading01: `
      font-family: ${fontFamily.sfpro};
      font-size: ${fontSize.size08};
      font-weight: ${fontWeight.bold};
      letter-spacing: ${units.px(0)};
    `,
    heading02: `
      font-family: ${fontFamily.sfpro};
      font-size: ${fontSize.size05};
      font-weight: ${fontWeight.semiBold};
      letter-spacing: ${units.px(2)};
    `,
    heading03: `
      font-family: ${fontFamily.sfpro};
      font-size: ${fontSize.size04};
      font-weight: ${fontWeight.semiBold};
      letter-spacing: ${units.px(0)};
    `
  }
}