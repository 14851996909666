import React from 'react'
import styled from 'styled-components'
import { IDateTime } from './types'

export const DateTime: React.FC<IDateTime> = ({ className, date, size="md" }) => {
  return (
    <DateTimeWrap className={className} date={date} size={size}>
      {date}
    </DateTimeWrap>
  )
}

const DateTimeWrap = styled.div<IDateTime>`
  color: var(--tg-theme-subtitle-text-color);
  font-size: 12px;
`
