export const TaskStatus = {
  TODO: 'TODO',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  CANCELLED: 'CANCELLED',
  BLOCKED: 'BLOCKED',
  REVIEW: 'REVIEW',
  TESTING: 'TESTING',
  ON_HOLD: 'ON_HOLD'
};

export type TaskStatus = (typeof TaskStatus)[keyof typeof TaskStatus]

export interface IUser {
  id: number
  tgId: number
  firstName: string
  lastName?: string
  username?: string
  photoUrl?: string
}

export interface IReqTasks {
  status?: TaskStatus
  priority?: number
  createdById?: number
  assignedToId?: number
}

export interface IReqUser {
  tgId: number
  firstName: string
  lastName?: string
  username?: string
  photoUrl?: string
}

export interface ITask {
  id: number
  title: string
  description: string
  status: TaskStatus
  priority: number
  dueDate: string
  createdDate: string
  updatedDate: string
  createdById: number
  createdBy: IUser
  assignedToId: number
  assignedTo: IUser
  figmaLink: string
  storybookLink: string
}
