import React from "react";
import styled from "styled-components";
import { IUser } from "shared/api";

export const User: React.FC<IUser> = ({lastName, firstName, photoUrl, username}) => {
  return (
    <UserWrap>
      {photoUrl ? (
        <img className="ava-img" src={photoUrl} alt={`${lastName} ${firstName}`}/>
      ): (<AvaStyled>{firstName.substring(0,1)}{lastName?.substring(0, 1)}</AvaStyled>)}
      <span>{lastName} {firstName}</span>
    </UserWrap>
  );
};

const UserWrap = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing--03);
  color: var(--tg-theme-text-color);
    
  .ava-img {
    display: block;
    border-radius: 50%;
    width: var(--spacing--07);
    height: var(--spacing--07);
  }
`

const AvaStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding-top: 2px;
  width: var(--spacing--07);
  height: var(--spacing--07);
  background-color: var(--tg-theme-button-color);
  color: var(--tg-theme-button-text-color);
`