import { sample } from "effector";
import { createGate } from "effector-react";
import { fxGetTasks, fxGetUserTasks } from "entities/task";
import { $session } from "entities/user";

export const TasksGate = createGate();

/**
 * Запрос всех задач
 */
sample({
  clock: TasksGate.open,
  fn: () => ({}),
  target: fxGetTasks
})

/**
 * Мои задачи
 */
sample({
  source: $session,
  clock: TasksGate.open,
  fn: (session) => {
    return {
      assignedToId: session?.id as number,
    }
  },
  target: fxGetUserTasks
})