import { baseAPI } from "../base";
import { IReqTasks, ITask, TaskStatus } from "./types";

export const taskService  = {
  findAll: (params: IReqTasks) => baseAPI.get<ITask[]>(`/tasks`, {
    params
  }).then(response => response.data),

  findOne: (id: number) => baseAPI.get<ITask>(`/tasks/${id}`).then(response => response.data),

  start: (id: number, assignedToId: number) => baseAPI.patch<ITask>(`/tasks/${id}/start`, {
    assignedToId
  }).then(response => response.data),

  updateStatus: (id: number, status: TaskStatus) => baseAPI.patch<ITask>(`/tasks/${id}/update-status`, {
    status
  }).then(response => response.data),
};