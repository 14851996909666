import { createEvent, sample } from "effector";
import { createGate } from "effector-react";
import { $task, fxGetTask, fxStartTask, fxUpdateStatusTask } from "entities/task";
import { $session, renderUser } from "entities/user";
import { formatDateDMYHM } from "shared/lib";
import { type TaskStatus } from "shared/api";
import { IProp } from "./types";

export const TaskGate = createGate<string>();

sample({
  clock: TaskGate.open,
  fn: (id) => parseInt(id),
  target: fxGetTask
})

$task.reset(TaskGate.close)

export const $props = $task.map(data => {
  if(!data) return [];

  const res: IProp[] = [
    {
      title: "Дизайнер",
      content: renderUser(data.createdBy)
    },
  ];

  if(data.assignedTo) {
    res.push({
      title: "Разработчик",
      content: renderUser(data.assignedTo)
    })
  }

  if(data.updatedDate) {
    res.push({
      title: "Дата обновления",
      content: formatDateDMYHM(data.updatedDate)
    })
  }

  return res;
})

export const startTask = createEvent();
export const updateStatusTask = createEvent<TaskStatus>();

sample({
  clock: startTask,
  source: [$session, $task],
  fn: ([session, task]) => ({
    id: task?.id as number,
    assignedToId: session?.id as number,
  }),
  target: fxStartTask
})

sample({
  clock: updateStatusTask,
  source: $task,
  fn: (task, status) => ({
    id: task?.id as number,
    status,
  }),
  target: fxUpdateStatusTask
})

sample({
  clock: [fxStartTask, fxUpdateStatusTask],
  source: TaskGate.state,
  fn: (id) => parseInt(id),
  target: fxGetTask
})