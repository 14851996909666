import React from "react";
import styled from "styled-components";
import { IBaseComponent } from "shared/types";
import { Menu } from "widgets/menu";
import { Title } from "shared/ui";

export const HomePage: React.FC<IBaseComponent> = ({className}) => {
  return (
    <HomePageWrap className={className}>
      <Title>Сводка</Title>
      <Menu/>
    </HomePageWrap>
  );
};

const HomePageWrap = styled.div`
    padding-top: var(--spacing--05);

    .user-link {
        padding: var(--spacing--05);
        color: var(--link-01);
        ${({theme}) => theme.fontTokens.heading01}
    }

    & > .row {
        border-bottom: 1px solid var(--ui-02);
    }

    .team-link {
        border-right: 1px solid var(--ui-02);
    }
`