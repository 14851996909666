export const styles = {
  kind: {
    primary: {
      background: "var(--layer-layer-inverse-02)",
      hover: "var(--layer-layer-hover-01)",
      disabled: "var(--disabled-disabled-01)",

      color: "var(--text-text-placeholder)",
      disabledColor: "var(--disabled-disabled-02)",

      borderRadius: "var(--radius)"
    },
    secondary: {
      background: "var(--layer-layer-02)",
      hover: "var(--layer-layer-hover-01)",
      disabled: "var(--disabled-disabled-01)",

      color: "var(--text-01)",
      disabledColor: "var(--disable-02)"
    },
    danger: {
      background: "transparent",
      color: "var(--text-01)",
      disabled: "var(--disable-01)",
      hover: "var(--disable-01)",
      disabledColor: "var(--disable-02)"
    },
    tertiary: {
      background: "transparent",
      color: "var(--text-01)",
      disabled: "var(--disable-01)",
      hover: "var(--disable-01)",
      disabledColor: "var(--disable-02)"
    }
  },
  size: {
    sm: 'var(--spacing--06)',
    md: 'var(--spacing--09)'
  },
}
