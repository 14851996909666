import { createEffect, restore } from "effector";
import { IReqTasks, ITask, taskService } from "shared/api";

export const fxGetTasks = createEffect<IReqTasks, ITask[]>((params = {}) => {
  return taskService.findAll(params);
})

export const $taskAll = restore(fxGetTasks, []);
export const $taskAllPending = fxGetTasks.pending;


export const fxGetUserTasks = createEffect<IReqTasks, ITask[]>((params = {}) => {
  return taskService.findAll(params);
})

export const $userTaskAll = restore(fxGetUserTasks, []);
export const $userTaskAllPending = fxGetUserTasks.pending;