import React from "react";
import {IIcon} from "./types";

export const FigmaIcon: React.FC<IIcon> = ({width = 24, height = 37}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 37" fill="none">
      <g clipPath="url(#clip0_1157_2501)">
        <path d="M12 18.7087C12 17.1174 12.6321 15.5913 13.7574 14.4661C14.8826 13.3409 16.4087 12.7087 18 12.7087C19.5913 12.7087 21.1174 13.3409 22.2426 14.4661C23.3679 15.5913 24 17.1174 24 18.7087C24 20.3 23.3679 21.8262 22.2426 22.9514C21.1174 24.0766 19.5913 24.7087 18 24.7087C16.4087 24.7087 14.8826 24.0766 13.7574 22.9514C12.6321 21.8262 12 20.3 12 18.7087Z" fill="#1ABCFE"/>
        <path d="M0 30.7087C0 29.1174 0.632141 27.5913 1.75736 26.4661C2.88258 25.3409 4.4087 24.7087 6 24.7087H12V30.7087C12 32.3 11.3679 33.8262 10.2426 34.9514C9.11742 36.0766 7.5913 36.7087 6 36.7087C4.4087 36.7087 2.88258 36.0766 1.75736 34.9514C0.632141 33.8262 0 32.3 0 30.7087H0Z" fill="#0ACF83"/>
        <path d="M12 0.70874V12.7087H18C19.5913 12.7087 21.1174 12.0766 22.2426 10.9514C23.3679 9.82616 24 8.30004 24 6.70874C24 5.11744 23.3679 3.59132 22.2426 2.4661C21.1174 1.34088 19.5913 0.70874 18 0.70874L12 0.70874Z" fill="#FF7262"/>
        <path d="M0 6.70874C0 8.30004 0.632141 9.82616 1.75736 10.9514C2.88258 12.0766 4.4087 12.7087 6 12.7087H12V0.70874H6C4.4087 0.70874 2.88258 1.34088 1.75736 2.4661C0.632141 3.59132 0 5.11744 0 6.70874H0Z" fill="#F24E1E"/>
        <path d="M0 18.7087C0 20.3 0.632141 21.8262 1.75736 22.9514C2.88258 24.0766 4.4087 24.7087 6 24.7087H12V12.7087H6C4.4087 12.7087 2.88258 13.3409 1.75736 14.4661C0.632141 15.5913 0 17.1174 0 18.7087H0Z" fill="#A259FF"/>
      </g>
      <defs>
        <clipPath id="clip0_1157_2501">
          <rect width="24" height="36" fill="white" transform="translate(0 0.70874)"/>
        </clipPath>
      </defs>
    </svg>
  );
};