import React from 'react'
import styled from 'styled-components'
import { ICol } from './types'

export const Col: React.FC<ICol> = ({ className, children, span = 1 }) => {
  return (
    <ColWrap className={className} span={span}>
      {children}
    </ColWrap>
  )
}

const ColWrap = styled.div<ICol>`
  grid-column: span ${({ span }) => span};
`
