import React from "react";
import styled from "styled-components";
import { IBaseComponent } from "shared/types";
import { NavLink } from "react-router-dom";
import { CheckIcon, HomeIcon } from "shared/ui";

// @ts-ignore
const setActive = ({ isActive }) => (isActive ? "active" : "");

export const Menu: React.FC<IBaseComponent> = ({className}) => {
  return (
    <MenuWrap className={className}>
      <MenuLink to="/" className={setActive}>
        <HomeIcon/>
        <span>Стартовая</span>
      </MenuLink>
      <MenuLink to="/tasks">
        <CheckIcon/>
        <span>Задачи</span>
      </MenuLink>
    </MenuWrap>
  );
};

const MenuWrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: var(--spacing--09);
  padding: var(--spacing--05) 0 var(--spacing--06);
  border-top: 1px solid var(--fill-colors-quarternary);
  background: var(--tg-theme-section-bg-color);
`

const MenuLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75px;
  text-decoration: none;
  color: var(--accent-grey-03);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.078px;
  
  &.active {
    color: var(--accent-black);
    
    svg path {
      fill: var(--accent-black);
    }
  }
  
  
`