import React from 'react'
import styled from 'styled-components'
import { IBaseLayout } from './types'
import { Container } from './Container'

export const BaseLayout: React.FC<IBaseLayout> = ({ className, children }) => {
  return (
    <BaseLayoutWrap className={className}>
      {children}
    </BaseLayoutWrap>
  )
}

const BaseLayoutWrap = styled(Container)`
  height: 100vh;
  padding: 20px 0;
`
