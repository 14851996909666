import React from 'react'
import styled from 'styled-components'
import { IStack } from './types'

export const Stack: React.FC<IStack> = ({ className, children, gap = "05", fullHeight = false, direction = 'vertical' }) => {
  return (
    <StackWrap className={className} gap={gap} direction={direction} fullHeight={fullHeight}>
      {children}
    </StackWrap>
  )
}

const StackWrap = styled.div<IStack>`
  display: flex;
  gap: ${({ gap }) => `var(--spacing--${gap})`};
  flex-direction: ${({ direction }) => (direction === 'vertical' ? 'column' : 'row')};
  height: ${({ fullHeight }) => fullHeight ? '100%' : 'auto'};
`
