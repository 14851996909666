import { createEffect, restore } from "effector";
import { authService, IReqUser, IUser } from "shared/api";

export const fxGetSession = createEffect<IReqUser, IUser>((params) => {
  return authService.session(params);
})

export const $session = restore(fxGetSession.doneData, null);

$session.watch(data => {
  console.log('$session', data)
})