import React from 'react'
import styled from 'styled-components'
import { IGrid } from './types'

export const Grid: React.FC<IGrid> = ({ className, children, count = 12, gap = 20 }) => {
  return (
    <GridWrap className={className} count={count} gap={gap}>
      {children}
    </GridWrap>
  )
}

const GridWrap = styled.div<IGrid>`
  display: grid;
  grid-template-columns: repeat(${({ count }) => count}, 1fr);
  gap: ${({ gap }) => gap}px;
`
