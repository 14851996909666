import React from "react";
import styled from "styled-components";
import {IBaseComponent} from "shared/types";

export const Card: React.FC<IBaseComponent> = ({className, children}) => {
  return (
    <CardWrap className={className}>
      {children}
    </CardWrap>
  );
};

const CardWrap = styled.div`
  padding: var(--spacing--05);
`