import React, { useEffect } from "react";
import { fxGetSession, $session } from "entities/user";
import { IBaseComponent } from "shared/types";
import { useTelegram } from "./useTelegram";
import { useUnit } from "effector-react/effector-react.mjs";

export const AuthProvider: React.FC<IBaseComponent> = ({children}) => {
  const {user, tg} = useTelegram();
  const [session, loading] = useUnit([$session, fxGetSession.pending]);

  useEffect(() => {
    if(user) {
      fxGetSession({
        tgId: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        username: user.username,
        photoUrl: user.photo_url
      })
    }

  }, [user])

  if(loading) {
    return null
  }

  if(1) {
    return (
      <div>{children}</div>
    )
  }

  return (
    <div>Нет авторизации</div>
  )
};