import { createEffect, restore, sample } from "effector";
import { ITask, taskService } from "shared/api";
import { IStartTaskReq, IUpdateStatusTaskReq } from "./types";

export const fxGetTask = createEffect((id: number) => {
  return taskService.findOne(id);
})

export const $task = restore(fxGetTask.doneData, null);
export const $taskPending = fxGetTask.pending;

export const fxStartTask = createEffect<IStartTaskReq, ITask>(({id, assignedToId}) => {
  return taskService.start(id, assignedToId);
})

export const fxUpdateStatusTask = createEffect<IUpdateStatusTaskReq, ITask>(({id, status}) => {
  return taskService.updateStatus(id, status);
})