import React from 'react'
import styled, { css } from 'styled-components'
import { IContainer } from './types'

export const Container: React.FC<IContainer> = ({ className, children, fluid = false }) => {
  return (
    <ContainerWrap className={className} fluid={fluid}>
      {children}
    </ContainerWrap>
  )
}

const ContainerWrap = styled.div<IContainer>`
  width: 100%;
  min-height: 100vh;
  padding: 0 var(--spacing--05);
  margin-right: auto;
  margin-left: auto;
  //max-width: 390px;
  //overflow: hidden;

  ${({fluid}) => fluid && css`
    padding-right: 0;
    padding-left: 0;
  `}
`
