import React from "react";
import styled from "styled-components";
import { Stack } from "shared/ui";
import { TaskCard } from "./TaskCard";
import { ITaskList } from "./types";

export const TaskList: React.FC<ITaskList> = ({tasks, className}) => {

  if(!tasks.length) {
    return (
      <div>Нет задач</div>
    )
  }

  return (
    <TaskListWrap gap="03" className={className}>
      {tasks.map((task) => (
        <TaskCard {...task}/>
      ))}
    </TaskListWrap>
  );
};

const TaskListWrap = styled(Stack)`

`